"use strict";
const header = document.getElementById("topbar");
const isSafari = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

var lastScrollTop = 0;
var touchstartPos = 0;
var touchendPos = 0;
const landingAnchors = {};

class ModalVideo {
	constructor() {
		this.modal = document.createElement("section");
		this.modal.classList.add("modal-video");
		this.modalInner = `<article>
												<video controls="" autoplay="" name="media"><source src="" type="video/mp4"></video>
												<aside>&times;</aside>
                       </article`;

		this.modal.insertAdjacentHTML("beforeend", this.modalInner);
		this.modalVideo = this.modal.querySelector("video");

		this.modalYT = document.createElement("section");
		this.modalYT.classList.add("modal-video");
		this.modalInnerYT = `<article class="modal-video-yt">
												<iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                       <aside>&times;</aside>
                       </article`;
		this.modalYT.insertAdjacentHTML("beforeend", this.modalInnerYT);
		this.modalVideoYT = this.modalYT.querySelector("iframe");

		this.eventHandlerRegistry();

		// this.modalTimeline = gsap.timeline({paused: true, defaults: {duration: 0.5, ease: "expo.easeInOut"},
		//   onReverseComplete: () => {
		//     this.destroy();
		//   },
		//   onStart: () => {},
		//   onComplete: () => {}
		// })

		// this.modalTimeline
		// 	.set('body', {overflow: "hidden"})
		//   .to(this.modal, {opacity: 1, duration: 0.3})
		//   .to(this.modalWrapper, {opacity: 1, y: 0, duration: 0.3})
		//   .to(this.modalClose, {opacity: 1, duration: 0.5});
	}

	destroy() {
		document.body.removeChild(document.querySelector(".modal"));
	}

	create(videoURI) {
		this.modalVideo.src = videoURI;
		// this.modal.style.height = `${document.body.offsetHeight}px`;
		document.body.appendChild(this.modal);

		// this.modalWrapper.style.top = `${window.pageYOffset + (window.outerHeight/2 - this.modalWrapper.offsetHeight/2) }px`

		document.body.style.overflow = "hidden";
		this.modal.style.opacity = "1";

		// this.modalTimeline.play();
	}

	createYT(videoURI) {
		this.modalVideoYT.src = videoURI;
		// this.modal.style.height = `${document.body.offsetHeight}px`;
		document.body.appendChild(this.modalYT);

		// this.modalWrapper.style.top = `${window.pageYOffset + (window.outerHeight/2 - this.modalWrapper.offsetHeight/2) }px`

		document.body.style.overflow = "hidden";
		this.modalYT.style.opacity = "1";

		// this.modalTimeline.play();
	}

	eventHandlerRegistry() {
		//handler generico che apre modale video al click su un elemento con attributo data-video

		[].slice.call(document.querySelectorAll("#watchVideo")).forEach((el) => {
			el.addEventListener("click", (e) => {
				e.preventDefault();

				let videoSrc = document.querySelector(".image-wrapper.image-shadow.fill video");
				if (videoSrc.getAttribute("data-iframevideo")) {
					this.create(videoSrc.getAttribute("data-iframevideo"));
				} else {
					this.create(videoSrc.getAttribute("src"));
				}
			});
		});
		[].slice.call(document.querySelectorAll("#watchVideoYT")).forEach((el) => {
			el.addEventListener("click", (e) => {
				e.preventDefault();
				this.createYT(e.target.getAttribute("data-url"));
			});
		});

		document.addEventListener("click", (e) => {
			if (e.target.matches("aside") || e.target.matches(".modal-video")) {
				// this.modalTimeline.reverse();

				this.modalVideo.pause();
				document.body.style.overflow = "auto";
				this.modal.style.opacity = "0";
				this.modalYT.style.opacity = "0";
				setTimeout(() => {
					this.modal.remove();
					this.modalYT.remove();
				}, 300);
			}
		});
	}
}

new ModalVideo();

if (document.querySelector(".hero_background picture img")) {
	document.querySelector(".hero_background picture img").addEventListener("click", () => {
		dataLayer.push({
			event: ["click-banner_150_years", "click", `'${site_locale}'`],
		});
	});
}

const headerSwitchMapper = ["invert-color-header"];
var productModelsSwiper;
const tecnorib = document.getElementById("tecnorib");

const pathArray = window.location.href.split("#");

if (window.location.href.indexOf(".html#") > -1) {
	let topOfElement = window.pageYOffset + document.querySelector(`#${pathArray[1]}`).getBoundingClientRect().top;
	window.scroll({ top: topOfElement, behavior: "smooth" });
}

document.addEventListener("DOMContentLoaded", function (event) {
	headerWhite();
	// initHeaderColorListener();
	// initHeaderColorSwitch();
	attachSideMenuListener();
	initNoStickyHeader();
	initLearnMorePopup();
	initLearnMoreTextPopup();

	if (document.getElementById("hp")) {
		initHome();
	} else {
		initProduct();
	}
	var queryParamObject = checkQueryParam();
	console.log("queryParamObject: ", queryParamObject);
	if (queryParamObject.length > 0) {
		goToAnchor(queryParamObject[0].value);
	}
});

function checkQueryParam() {
	var urlParams = [];
	(window.onpopstate = function () {
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, " "));
			},
			query = window.location.search.substring(1);

		while ((match = search.exec(query)))
			urlParams.push({
				key: decode(match[1]),
				value: decode(match[2]),
			});
		//  urlParams[decode(match[1])] = decode(match[2]);
	})();
	return urlParams;
}

function initHome() {
	initProductGallerySwiper();
	initInstagramFeed();
	initArticleSwiper();
}

function goToAnchor(anchorClass) {
	console.log("initQueryHandler START ", anchorClass);
	// document.getElementsByClassName('landing-anchor') &&
	// Array.prototype.forEach.call(document.getElementsByClassName('landing-anchor'),function( landingAnchor){
	//   landingAnchors[landingAnchor.getAttribute('landing-name')]=landingAnchor;
	// })
	setTimeout(function () {
		var classname = "landing-anchor-" + anchorClass;
		document.getElementsByClassName(classname).length >= 0 &&
			document.getElementsByClassName(classname)[0].scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		console.log("initQueryHandler END");
	}, 1000);
}

function initNoStickyHeader() {
	console.log("initNoStickyHeader ", isSafari ? "is safari" : "is chrome");
	if (window.innerWidth > 768) {
		document.body.addEventListener("wheel", checkScrollDirection);
	} else {
		if (!isSafari) {
			document.body.addEventListener("wheel", checkScrollDirection);
		} else {
			document.body.addEventListener("touchstart", function (e) {
				console.log("touchstart event is", e);
				touchstartPos = e.touches[0].clientY;
			});
			document.body.addEventListener("touchend", function (e) {
				var touchendPos = e.changedTouches[0].clientY;
				console.log("scrolling");
				if (touchstartPos > touchendPos + 5) {
					console.log("scrolling upward");
					handleScrollUpward();
				} else if (touchstartPos < touchendPos - 5) {
					console.log("scrolling downward");
					handleScrollDownward();
				}
			});
			window.onscroll = checkScrollDirection();
		}
	}
}

function checkScrollDirection(event) {
	if (header.classList.contains("side__menu-open")) return;
	if (checkScrollDirectionIsUp(event)) {
		handleScrollDownward();
	} else {
		handleScrollUpward();
	}
}

function checkScrollDirectionIsUp(event) {
	if (event && event.wheelDelta) {
		return event.wheelDelta > 0;
	} else if (event && event.deltaY) {
		return event.deltaY < 0;
	}
	// else{
	//   console.log('safari scroll')
	//     var st = window.pageYOffset || document.documentElement.scrollTop;
	//     var directionUp=false;
	//     if (st > lastScrollTop){
	//         directionUp = false;
	//     } else {
	//         directionUp = true;
	//     }
	//     lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	//     return directionUp;
	// }
}

function handleScrollUpward() {
	isSafari && elevateStickyCta();
	hideHeader();
}

function handleScrollDownward() {
	isSafari && lowerStickyCta();
	showHeader();
}

function elevateStickyCta() {
	document.getElementById("sticky_cta") && document.getElementById("sticky_cta").classList.add("elevated");
}

function lowerStickyCta() {
	document.getElementById("sticky_cta") && document.getElementById("sticky_cta").classList.remove("elevated");
}

function hideHeader() {
	header.classList.add("hidden");
}

function showHeader() {
	header.classList.remove("hidden");
}

function initProductGallerySwiper() {
	console.log("initProductGallerySwiper");

	var slidesPerView = 5;
	if (window.innerWidth < 768) slidesPerView = 2;

	var spaceBetween = 40;
	if (window.innerWidth < 768) spaceBetween = 20;
	var options = {
		slidesPerView: slidesPerView,
		spaceBetween: spaceBetween,
		centeredSlides: true,
		loop: true,
		on: {
			onSlideChangeEnd: function (e) {
				window["product-gallery-swiper"].update(true);
			},
		},
	};
	if (window.innerWidth > 768)
		options.pagination = {
			el: ".js-swiper-pagination-gallery",
			type: "bullets",
			clickable: true,
		};
	window["product-gallery-swiper"] = new Swiper("#product-gallery-swiper", options);
}

function checkNumberOfVariant(target) {
	var options = {
		slidesPerView: 1,
		spaceBetween: 20,
		centeredSlides: false,
		pagination: {
			el: ".swiper-pagination-inner",
			type: "bullets",
			clickable: true,
		},
	};

	if (document.querySelectorAll(target)?.[0]?.closest(".inner-wrapper")?.querySelector(".js-productColor-name")) {
		options.on = {
			slideChange: function () {
				var colorNames = this.el.closest(".inner-wrapper").querySelectorAll(".productColor-name__colorName");
				var CurrentColor = "colorName__" + this.realIndex;
				for (var i = 0; i < colorNames.length; i++) {
					if (colorNames[i].getAttribute("data-colorname") == CurrentColor) {
						console.log("found");
						colorNames[i].classList.add("active");
					} else {
						colorNames[i].classList.remove("active");
					}
				}

				var colorEdition = this.$el[0].closest(".inner-wrapper").querySelectorAll(".js-color-edition-dynamicLabel");
				console.log(colorEdition);
				var CurrentColorEdition = "color-edition-" + this.realIndex;
				console.log("colorEdition searching for ", CurrentColorEdition);
				for (var i = 0; i < colorEdition.length; i++) {
					var coloreditionName = colorEdition[i].getAttribute("data-coloredition");
					console.log("colorEdition matching with ", coloreditionName);

					if (coloreditionName == CurrentColorEdition) {
						console.log("found");
						colorEdition[i].classList.add("active");
					} else {
						colorEdition[i].classList.remove("active");
					}
				}

				//insert here code for changing edition label
			},
		};
	}

	if (document.querySelectorAll(target)?.[0]?.classList?.contains("verticalSwiper")) {
		if (window.innerWidth > 768) {
			options.direction = "vertical";
		}
	}
	if (document.querySelectorAll(".js-productColor-name")?.length > 0) {
		document.querySelectorAll(".js-productColor-name").forEach(function (colorLabelsWrapper) {
			if (!colorLabelsWrapper.children[1]) return;
			colorLabelsWrapper.children[1].classList.add("active");
		});
	}
	var colorSwiper = new Swiper(target, options);
}

function slideChangeHandler(swiperInstance) {}

function changeColorName(index) {}

function initInstagramFeed() {
	console.log("initInstagramFeed");

	var slidesPerColumn = 2;
	var slidesPerView = 4.4;

	if (window.innerWidth < 768) {
		slidesPerColumn = 4;
		slidesPerView = 3;
	}
	var instaSwiper = new Swiper("#instafeed-swiper", {
		slidesPerView: slidesPerView,
		slidesPerColumn: slidesPerColumn,
		spaceBetween: 20,
		centeredSlides: true,
		loop: true,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
}

function initArticleSwiper() {
	var slidesPerView = window.innerWidth < 768 ? 1.2 : 3;
	var speed = window.innerWidth < 768 ? 500 : 1500;

	console.log("initArticleSwiper");
	var swiper = new Swiper("#article-swiper", {
		slidesPerView: slidesPerView,
		spaceBetween: 20,
		centeredSlides: true,
		loop: true,
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		speed: speed,
		autoplay: {
			delay: 3000,
		},
	});
}

function attachSideMenuListener() {
	document.querySelectorAll(".menu-toggler").forEach(function (button) {
		button.addEventListener("click", function (e) {
			e.preventDefault();
			if (header.classList.contains("side__menu-open")) {
				header.classList.remove("side__menu-open");
				document.querySelector("body").classList.remove("hidden");
				document.getElementById("black-layer-overlay").classList.remove("visible");
			} else {
				header.classList.add("side__menu-open");
				document.querySelector("body").classList.add("hidden");
				document.getElementById("black-layer-overlay").classList.add("visible");
			}
		});
	});
	document.querySelector("#black-layer-overlay").addEventListener("click", function () {
		header.classList.remove("side__menu-open");
		document.querySelector("body").classList.remove("hidden");
		document.getElementById("black-layer-overlay").classList.remove("visible");
	});
}

// function initHeaderColorListener(){
//   if(!document.getElementById('hp')){
//     headerBlack();
//     return;
//   }
//   document.addEventListener('scroll', function(){
//     var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
//     // console.log(scrollTop)
//     if( scrollTop > (window.innerHeight)){
//       headerBlack();
//     }else{
//       headerWhite();
//     }
//   })
// }

function initHeaderColorSwitch() {
	document.body.addEventListener("wheel", checkIfColorSwitchIsNeeded);
}

function checkIfColorSwitchIsNeeded() {
	if (!document.getElementsByClassName("invert-color-header")[0]) {
		return;
	}
	headerSwitchMapper.forEach(function (elem) {
		var inView = isElementInViewport(document.getElementsByClassName("invert-color-header")[0]);
		console.log("is " + elem + " in viewport? " + inView);
		if (inView) {
			headerWhite();
		} else {
			headerBlack();
		}
	});
}

function toggleHeaderColor() {
	if (header.classList.contains("topbar--black-theme")) {
		headerWhite();
	} else {
		headerBlack();
	}
}

function headerBlack() {
	header.classList.add("topbar--black-theme");
}

function headerWhite() {
	header.classList.remove("topbar--black-theme");
}

function initProduct() {
	if (document.getElementById("product-models") && document.getElementById("product-models").querySelectorAll(".swiper-slide.product-slide").length > 1) initProductModelSwiper("#product-models", 1.2);
	if (document.getElementById("product-models-2")) initProductModelSwiper("#product-models-2", 1.2);
	if (document.getElementById("product-models-2") && document.getElementById("product-models-2").querySelectorAll(".swiper-slide.product-slide").length === 1) {
		console.log("in");
		Array.from(document.getElementById("product-models-2").querySelectorAll(".swiper-button-next, .swiper-button-prev")).forEach((el) => {
			console.log("el", el);
			el.style.display = "none";
		});
	}
	if (document.getElementById("product-feature")) initProductModelSwiper("#product-feature", 1);
	initProductGallerySwiper();
	if (window.innerWidth > 768) {
		checkNumberOfVariant(".swiper-container-inner");
	} else {
		checkNumberOfVariant(".swiper-container-inner-popup");
	}
	tecnorib ? initTecnoribSpecific() : null;
}

function initProductModelSwiper(parentId, mobileSlidesPerView) {
	var slidesPerView = 1;
	if (window.innerWidth < 768) slidesPerView = mobileSlidesPerView;
	var options = {
		slidesPerView: slidesPerView,
		spaceBetween: 20,
		centeredSlides: true,
		loop: false,
		updateOnWindowResize: true,
		navigation: {},
		pagination: {},
	};

	if (document.querySelectorAll(parentId + " .swiper-slide-outer").length == 1) {
		options.loop = false;
		options.allowTouchMove = false;
	}

	if (document.querySelector(parentId + " .swiper-pagination")) {
		options.pagination = {
			el: parentId + " .swiper-pagination",
			type: "bullets",
		};
	}

	if (document.querySelector(parentId + " .swiper-button-next") && document.querySelector(parentId + " .swiper-button-prev")) {
		options.navigation.nextEl = ".swiper-button-next";
		options.navigation.prevEl = ".swiper-button-prev";
	}

	if (document.querySelector(parentId + " .swiper--tabs-wrapper + #productModelsSwiper-2") && window.location.href.indexOf("/roger-dubuis") != -1) {
		options.allowTouchMove = false;
	}

	var swiperTarget = parentId + " .swiper-container-outer";
	window[parentId] = new Swiper(swiperTarget, options);
	var string = parentId + " .swiper--tabs-wrapper";
	if (document.querySelectorAll(string)) {
		document.querySelectorAll(parentId + " .swiper--tabs-wrapper .js-tab").forEach(function (tab) {
			tab.addEventListener("click", function (event, tab) {
				event.preventDefault();
				var nextIndex = event.currentTarget.getAttribute("slide-to-index");
				window[parentId].slideTo(nextIndex - 1);
			});
		});
	}
	if (document.querySelector(parentId + " .swiper--tabs-wrapper")) {
		document.querySelectorAll(parentId + " .js-tab")[0].classList.add("active");
		window[parentId].on("slideChangeTransitionEnd", function () {
			document.querySelectorAll(parentId + " .js-tab").forEach(function (tab, index) {
				var slideindex = window[parentId].realIndex;
				console.log("clicked index:" + index + " going to :" + slideindex);
				if (index == slideindex) {
					tab.classList.add("active");
				} else {
					tab.classList.remove("active");
				}
			});
		});
	}
}

function isElementInViewport(el) {
	var rect = el.getBoundingClientRect();
	return (
		rect.top <= 0 && //this to be sure that the div is under the header
		rect.bottom >= 0
	);
}

function initLearnMorePopup() {
	document.querySelectorAll(".js-swiper-slide__learnmorecta").forEach(function (cta) {
		cta.addEventListener("click", function (e) {
			e.preventDefault();
			console.log("clicked ctaId " + cta.getAttribute("popupcta-id"));
			showLearnMorePopup(cta.getAttribute("popupcta-id"));
		});
	});
	document.querySelectorAll(".closeicon").forEach(function (closeIcon) {
		closeIcon.addEventListener("click", function (e) {
			e.preventDefault();
			hideLearnMorePopup(closeIcon.getAttribute("popupcta-id"));
		});
	});
}

function showLearnMorePopup(popup_id) {
	console.log("searching popup: " + popup_id);
	document.getElementById(popup_id);
	document.getElementById(popup_id) && document.getElementById(popup_id).classList.add("popup-visible");
	document.getElementById("product-models").classList.add("learnmore-opened");
	setTimeout(function () {
		document.getElementsByTagName("body")[0].classList.add("positionFixed");
	}, 500);
}
function hideLearnMorePopup(popup_id) {
	console.log("searching popup: " + popup_id);
	document.getElementById(popup_id);
	document.getElementById(popup_id) && document.getElementById(popup_id).classList.remove("popup-visible");
	document.getElementsByTagName("body")[0].classList.remove("positionFixed");
	var elmnt = document.getElementById("product-models");
	elmnt.scrollIntoView();

	document.getElementById("product-models").classList.remove("learnmore-opened");
	var parentId = document.getElementById(popup_id) && document.getElementById(popup_id).closest(".product-models").id;
	var poundedParentId = "#" + parentId;
	setTimeout(function () {
		updateSwiper(poundedParentId);
	}, 100);
}

function updateSwiper(parentId) {
	console.log("updating awiper inside id:" + parentId);
	window[parentId].update();
}

function initLearnMoreTextPopup() {
	document.querySelectorAll(".js-swiper-slide__learnmorecta-text").forEach(function (cta) {
		cta.addEventListener("click", function (e) {
			e.preventDefault();
			showLearnMoreTextPopup(cta.getAttribute("popupcta-id"));
		});
	});
	document.querySelectorAll(".learnMoreTextPopup__closeicon").forEach(function (cta) {
		cta.addEventListener("click", function (e) {
			e.preventDefault();
			hideLearnMoreTextPopup(e.currentTarget.getAttribute("popupcta-id"));
		});
	});
}

function showLearnMoreTextPopup(popupcta_id) {
	console.log("clicked ctaId " + popupcta_id);
	document.getElementById(popupcta_id).classList.add("visible");
}

function hideLearnMoreTextPopup(popupcta_id) {
	console.log("clicked ctaId " + popupcta_id);
	document.getElementById(popupcta_id).classList.remove("visible");
}

function initTecnoribSpecific() {
	initWidget("tecnorib");
}

function initWidget(pageId) {
	initwidgetSwiper(pageId);
	initCtaListener(pageId);
}

function initwidgetSwiper(pageId) {
	document.getElementById(pageId + "_swiper-wrapper-left");
	window[pageId + "_widget_1"] = new Swiper("#" + pageId + "_swiper-wrapper-left", {
		slidesPerView: 1,
		spaceBetween: 20,
		centeredSlides: true,
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	window[pageId + "_widget_2"] = new Swiper("#" + pageId + "_swiper-wrapper-right", {
		slidesPerView: 1,
		spaceBetween: 20,
		centeredSlides: true,
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
}

function initCtaListener(pageId) {
	document.querySelector(".speedboatwidget-container__leftcolumn__cta.speedboatwidget__cta") &&
		document.querySelector(".speedboatwidget-container__leftcolumn__cta.speedboatwidget__cta").addEventListener("click", function (event) {
			document.getElementById("product-models").scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		});
	document.querySelector(".speedboatwidget-container__rightcolumn__cta.speedboatwidget__cta") &&
		document.querySelector(".speedboatwidget-container__rightcolumn__cta.speedboatwidget__cta").addEventListener("click", function (event) {
			document.getElementById("product-models-2").scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		});
}
